/* Demo 2 Homepage 2 */
/* Fonts */
@import url("https://fonts.googleapis.com/css?family=Poppins:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800");
/* font-family: 'Poppins', sans-serif; */
.footer-bottom .container.no-before:before {
  height: 0 !important; }

/* Categories products on homepage */
.container.categories .product .product-body .product-title a:hover {
  color: var(--primary-color, #a6c76c); }

.container.categories .product .product-action .btn-cart:hover, .container.categories .product .product-action .btn-cart:focus {
  background-color: var(--primary-color, #a6c76c);
  border-color: var(--primary-color, #a6c76c); }

.intro-slider-container,
.intro-slide {
  height: 360px;
  background-color: #8a8988; }

.intro-slide {
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center center; }
  .intro-slide .btn-primary {
    color: #fff;
    background-color: transparent;
    border-color: #fff; }
    .intro-slide .btn-primary:hover, .intro-slide .btn-primary:focus {
      background-color: var(--primary-color, #a6c76c);
      border-color: var(--primary-color, #a6c76c); }

.intro-content {
  position: static;
  left: auto;
  top: auto;
  transform: translateY(0);
  -ms-transform: translateY(0); }

.intro-subtitle {
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: -.01em; }

.intro-title {
  font-weight: 600;
  letter-spacing: -.025em; }
  .intro-title sup {
    vertical-align: top;
    font-size: 1.3rem;
    top: 1em;
    margin-right: 0;
    letter-spacing: -.01em; }
    .intro-title sup + sup {
      margin-left: .8rem; }

.brands-border .brand {
  height: 100px;
  border: .1rem solid #ebebeb;
  border-left-width: 0; }

.brands-carousel-full {
  border-bottom: .1rem solid #ebebeb; }
  .brands-carousel-full.brands-border .brand {
    border-right-width: 0;
    border-top-width: 0;
    border-bottom-width: 0; }
  .brands-carousel-full.brands-border .owl-item:first-child .brand {
    border-left-width: 0; }

.banner-group {
  margin-bottom: 3rem; }
  @media screen and (min-width: 992px) {
    .banner-group .col-lg-3 {
      -ms-flex: 0 0 26.05%;
      flex: 0 0 26.05%;
      max-width: 26.05%; }
    .banner-group .col-lg-4 {
      -ms-flex: 0 0 32.77%;
      flex: 0 0 32.77%;
      max-width: 32.77%; }
    .banner-group .col-lg-5 {
      -ms-flex: 0 0 41.18%;
      flex: 0 0 41.18%;
      max-width: 41.18%; } }

.banner-content.banner-content-top {
  top: 2.6rem;
  transform: translateY(0);
  -ms-transform: translateY(0); }

.banner-content.banner-content-bottom {
  bottom: 3rem; }

.banner-large .banner-content {
  left: 3rem; }

.btn.banner-link {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  text-transform: capitalize; }

.banner-subtitle {
  color: #666666;
  font-weight: 300;
  font-size: 1.4rem;
  letter-spacing: -.01em;
  margin-bottom: .6rem; }
  .banner-subtitle.text-grey {
    color: #ebebeb; }

.banner-title {
  color: #333;
  font-weight: 600;
  font-size: 2.4rem;
  letter-spacing: -.025em;
  margin-bottom: 1.4rem; }

.banner-text {
  color: #666666;
  font-weight: 300;
  font-size: 2.4rem;
  line-height: 1.25;
  letter-spacing: -.01em;
  margin-bottom: 1.3rem; }
  .banner-title + .banner-text {
    margin-top: -1.4rem; }
  .banner-large .banner-text {
    color: #333; }

.nav.nav-pills .nav-item.show .nav-link,
.nav.nav-pills .nav-item .nav-link.active {
  color: #333;
  border-bottom-color: #333; }

.nav.nav-border-anim .nav-link:before {
  background-color: #333; }

.nav-pills.nav-big .nav-item + .nav-item {
  margin-left: 1.5rem; }

.nav-pills.nav-big .nav-link {
  padding-top: .8rem;
  padding-bottom: .8rem;
  color: #ccc;
  font-weight: 600;
  font-size: 2.4rem;
  letter-spacing: -.025em;
  text-transform: capitalize; }
  .nav-pills.nav-big .nav-link:hover, .nav-pills.nav-big .nav-link:focus, .nav-pills.nav-big .nav-link.active {
    color: var(--primary-color, #a6c76c); }

.nav-pills.nav-big .nav-item.show .nav-link {
  color: var(--primary-color, #a6c76c); }

/* Product 8 */
.product.product-8 .product-body {
  padding-top: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem; }

.product.product-8 .new-price {
  color: #ef837b; }

.product.product-8 .btn-product {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background-color: var(--primary-color, #a6c76c); }
  .product.product-8 .btn-product span {
    font-size: 1.3rem; }
  .product.product-8 .btn-product:before {
    color: #fff; }
  .product.product-8 .btn-product:hover, .product.product-8 .btn-product:focus {
    background-color: var(--secondary-color, #92b25a); }

.deal-container .banner-subtitle {
  color: #666;
  margin-bottom: .9rem;
  font-weight: 400; }

.deal-container .banner-content.banner-content-top {
  top: 4rem; }

.deal-container .banner-text {
  font-weight: 600; }

.deal {
  display: flex;
  flex-direction: column-reverse;
  padding: 2rem;
  background-color: #fff;
  margin-bottom: 2rem; }

.deal-content {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 3.5rem 0 2rem; }
  .deal-content .btn {
    padding-top: .85rem;
    padding-bottom: .85rem;
    font-weight: 500;
    color: #333;
    background-color: transparent;
    border: .1rem solid #ebebeb; }
    .deal-content .btn:hover, .deal-content .btn:focus {
      color: #fff;
      border-color: var(--primary-color, #a6c76c);
      background-color: var(--primary-color, #a6c76c); }
  .deal-content h4 {
    color: var(--primary-color, #a6c76c);
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.3;
    letter-spacing: -.01em;
    margin-bottom: .5rem; }
  .deal-content h2 {
    font-weight: 600;
    font-size: 4rem;
    line-height: 1.1;
    letter-spacing: -.05em;
    margin-bottom: 3.6rem; }
  .deal-content .product-title {
    font-weight: 600;
    font-size: 2rem;
    letter-spacing: -.01em;
    margin-bottom: .7rem; }
  .deal-content .product-price {
    font-weight: 300;
    font-size: 2rem;
    margin-bottom: 2.4rem; }
  .deal-content .new-price {
    font-weight: 600; }

.deal-image {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }
  .deal-image > a {
    display: block; }

.deal-countdown {
  margin-bottom: 4rem;
  margin-left: -1rem;
  max-width: 270px; }
  .deal-countdown .countdown-show3 .countdown-section {
    width: calc(33.33% - 20px); }
  .deal-countdown .countdown-section {
    font-size: 1.2rem;
    line-height: 1;
    padding: 1.6rem 0;
    margin-left: 1rem;
    margin-right: 1rem;
    background-color: var(--primary-color, #a6c76c);
    border-radius: .3rem;
    border: .1rem solid #e1e1e1; }
    .deal-countdown .countdown-section:not(:last-child):after {
      color: #333333;
      content: ':';
      display: inline-block;
      font-weight: 400;
      font-size: 20px;
      line-height: 1;
      position: absolute;
      left: 100%;
      margin-left: 9px;
      margin-top: -1px;
      top: 50%;
      transform: translateY(-50%);
      -ms-transform: translateY(-50%); }
  .deal-countdown .countdown-period {
    display: block;
    font-size: 1.2rem;
    font-weight: 400;
    color: #fff;
    text-transform: lowercase;
    width: 100%;
    padding-left: .2rem;
    padding-right: .2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .deal-countdown .countdown-amount {
    display: inline-block;
    color: #fff;
    font-weight: 600;
    font-size: 3rem;
    letter-spacing: -.05em;
    margin-bottom: .4rem; }

.btn-more {
  text-transform: capitalize; }

.icon-box-side .icon-box-title {
  font-weight: 600; }

.icon-box-side p {
  font-weight: 400;
  letter-spacing: -.01em; }

@media screen and (min-width: 768px) {
  .deal {
    flex-direction: row; }
  .deal-content {
    -ms-flex: 0 0 47.8%;
    flex: 0 0 47.8%;
    max-width: 47.8%;
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .deal-image {
    -ms-flex: 0 0 52.2%;
    flex: 0 0 52.2%;
    max-width: 52.2%; } }

@media screen and (min-width: 1200px) {
  .deal-content {
    padding: 3.5rem 3rem 2rem; }
    .deal-content .btn {
      padding-top: 1.15rem;
      padding-bottom: 1.15rem; }
    .deal-content h4 {
      font-size: 2rem; }
    .deal-content h2 {
      font-size: 4rem; }
    .deal-content .product-title {
      font-size: 2.4rem; }
    .deal-content .product-price {
      font-size: 2.4rem; }
  .deal-countdown .countdown-section {
    padding-top: 2.1rem;
    padding-bottom: 2.1rem; }
  .deal-countdown .countdown-amount {
    font-size: 3rem; } }

.blog-posts .title {
  margin-bottom: 2.4rem; }

.blog-posts .entry-meta {
  font-weight: 400; }

.blog-posts .read-more {
  text-transform: capitalize;
  color: #333;
  font-size: 1.4rem; }
  .blog-posts .read-more:hover, .blog-posts .read-more:focus {
    color: var(--primary-color, #a6c76c); }

.footer .widget-about-info a {
  color: var(--primary-color, #a6c76c); }

.footer .widget-title {
  font-weight: 600; }

.footer-newsletter .input-group {
  flex-direction: column;
  align-items: center; }

.footer-newsletter .form-control {
  width: 100%;
  margin-bottom: 1rem; }

.footer-newsletter .btn-primary {
  background-color: #fff;
  color: #333;
  font-weight: 500;
  min-width: 150px;
  padding-left: 1rem;
  padding-right: 0;
  border: none; }
  .footer-newsletter .btn-primary:hover, .footer-newsletter .btn-primary:focus {
    color: #fff;
    background-color: var(--primary-color, #a6c76c);
    border-color: var(--primary-color, #a6c76c); }

@media screen and (min-width: 576px) {
  .intro-slider-container,
  .intro-slide {
    height: 400px; }
  .footer-newsletter .input-group {
    flex-direction: row;
    align-items: stretch; }
  .footer-newsletter .form-control {
    width: auto;
    margin-bottom: 0; } }

@media screen and (min-width: 768px) {
  .intro-slider-container,
  .intro-slide {
    height: 440px; }
  .intro-title sup {
    font-size: 1.6rem; }
  .banner-content.banner-content-top {
    top: 3.6rem; }
  .banner-content.banner-content-bottom {
    bottom: 4rem; }
  .banner-large .banner-content {
    left: 5rem; } }

@media screen and (min-width: 992px) {
  .intro-slider-container,
  .intro-slide {
    height: 460px; }
  .intro-title sup {
    font-size: 1.8rem; }
  .banner-large .banner-content {
    left: 7rem; } }

@media screen and (min-width: 1200px) {
  .intro-slider-container,
  .intro-slide {
    height: 500px; }
  .intro-content .btn {
    min-width: 170px; }
  .intro-title {
    font-size: 5rem; }
    .intro-title sup {
      font-size: 2rem; }
    .intro-title span {
      font-weight: 300; } }

.blog-posts .entry-title {
  font-weight: 600; }

@media screen and (min-width: 768px) {
  .intro-title {
    margin-bottom: 2.5rem; } }
